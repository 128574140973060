import React, { useEffect, useState } from 'react';
import './styles.scss';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import DefaultMapFilter from '../DefaultMapFilter/DefaultMapFilter';
import MapComponent from '../MapComponent/MapComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setDataPressItem } from '../../store/microdistDataReduces';
import Loader from '../Loader/Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ModalMap = ({ onClose, titleHeader, subTitleHeader, regionId, regionData, activeLandploat, isRedact, isCreateMicrodist, isCreateObject, drawType = 'polygon', drawColor = false }) => {
  const micropdistPlotInstance = new MicroDistrictApiRequest();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const [coordinatesItem, setCoordinatesItem] = useState([]);
  const [districtData, setDistrictData] = useState();
  const [isActiveLandploat, setIsActiiveLandploat] = useState(null);
  const location = useLocation()

  const microdistData = useSelector((state) => state.mocrodist_data);

  useEffect(() => {
    // Заблокировать прокрутку при открытии модального окна
    document.body.style.overflow = 'hidden'
    // Вернуть нормальную прокрутку при закрытии модального окна
    return () => {
      document.body.style.overflow = 'auto';
    };

  }, []);

  useEffect(() => {
    if (location.pathname === '/microdistrict') {
      console.log('location.pathname === \'/microdistrict\'', location.pathname === '/microdistrict')
      setTimeout(() => {
        setLoading(false)
      }, 2000)

    }
  }, [microdistData]);

  // useEffect(() => {
  //   if (regionData != undefined) {
  //     micropdistPlotInstance.mapApi(regionData, '?ignore_filters')
  //       .then((resp) => {
  //         if (resp.success) {
  //           const reversedCoordinates = resp.data.coordinates.map((item) => {
  //             return item
  //           }
  //           );
  //           setDistrictData(resp.data)

  //           resp.data.land_plots.map((item) => {

  //             if (item?.cadastral === activeLandploat?.cadastral) {
  //               setIsActiiveLandploat(item)
  //             }
  //           })

  //           setCoordinatesItem(reversedCoordinates);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Ошибка при получении данных:', error);
  //       });
  //   }
  // }, []);

  const setMapData = (data) => {
    const reversedCoordinates = data.coordinates.map((item) => (item));
    setDistrictData(data)
    data.land_plots.map((item) => {
      if (item?.cadastral === activeLandploat?.cadastral) {
        setIsActiiveLandploat(item)
      }
    })
    setCoordinatesItem(reversedCoordinates);
  }

  return (
    <div>
      <div className="modalContainerBg" onClick={onClose}></div>
      <div className="modalContainer">
        <div className="mapHeader">
          <h1>{titleHeader ? titleHeader : districtData?.name}</h1>
          <h3>{subTitleHeader}</h3>
        </div>

        <DefaultMapFilter setMapData={setMapData} regionId={regionId} setLoading={setLoading} />

        <div className='modalMapContainer'>
          {loading ? <div className='MapComponent'><Loader /></div> : isCreateMicrodist ?
            <MapComponent classes={'MapComponent _modal'}
              district={coordinatesItem}
              landplots={districtData?.land_plots}
              activeLandplot={false}
              districtRedact={true}
              isClick={false}
              isRedact={isRedact}
              save={(coordinates) => dispatch(setDataPressItem(['coordinates', coordinates]))}
              onClose={onClose}
            />
            : isCreateObject ? <MapComponent classes={'MapComponent _modal'}
              district={coordinatesItem}
              landplots={districtData?.land_plots}
              // activeLandplot={isActiveLandploat}
              geoObjects={[
                {
                  coordinates: microdistData?.coordinates ? microdistData?.coordinates : [],
                  type: drawType,
                  color: drawColor
                }
              ]}
              geoObjectRedact={true}
              drawType={drawType}
              drawColor={drawColor}
              isClick={false}
              isRedact={true}
              save={(coordinates) => dispatch(setDataPressItem(['coordinates', coordinates]))}
              onClose={onClose}
            /> : <MapComponent classes={'MapComponent _modal'}
              district={coordinatesItem}
              landplots={districtData?.land_plots}
              activeLandplot={isActiveLandploat}
              landplotRedact={true}
              isClick={false}
              isRedact={true}
              save={(coordinates) => dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, coordinates: coordinates }]))}
              onClose={onClose}
            />}
        </div>
      </div>
    </div>

  )
}

export default ModalMap