import React, { useEffect, useState } from 'react';
import Header from '../../component/Header/Header';
import icons from '../../assets/icons/icons';
import Tables from '../../component/Tables/Tables';
import { fieldToArray } from '../../component/UI/functions/functions';
import MicroDistrict from "../../api/microDistrict/MicroDistrict";
import { Link } from 'react-router-dom';
import Buttons from '../../component/Buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../component/Modal/Modal';
import FormInput from '../../component/FormInput/FormInput';
import { setLoading } from '../../store/loadingReducer';
import Loader from '../../component/Loader/Loader';
import groupUser from '../../locales/groupUser';
import { cleardataMicrodistrictFilter, setDataMicrodistrictFilterPressItem } from '../../store/filterMicrodistrictDataReduces';

const MicrodistrictTablePage = () => {

    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isCadastral = userData?.user?.groups?.some(group => group.name === groupUser.cadastralGroup);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);

    const [isList, setIsList] = useState([]);
    const [isFilterOption, setIsFilterOption] = useState()
    const [isData, setIsData] = useState();
    const [isUpdate, setIsUpdate] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [isDishardMicrodistMunic, setIsDishardMicrodistMunic] = useState({})
    const [isDishardMicrodistOne, setIsDishardMicrodistOne] = useState({})
    const [isDishardMicrodistTwo, setIsDishardMicrodistTwo] = useState({})


    const [offset, setOffset] = useState(0);
    const filterRenderer = useSelector((state) => state.data_microdistFilter);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);
    const [isModal, setIsModal] = useState(false)
    const microDistrict = new MicroDistrict();


    useEffect(() => {
        dispatch(setLoading(true));

        microDistrict.options().then((resp) => {
            if (resp.success) {

                setIsList(fieldToArray(resp?.data?.actions.list));
                setIsFilterOption(resp?.data?.actions.filter);
                const queryParams = fieldToArray(filterRenderer).map(item => `${item.key}=${item.value}`).join('&');
                const urlParams = `?${queryParams}`;

                microDistrict.list({ urlParams }).then(resp => {
                    if (resp.success) {
                        setIsData(resp.data);
                        dispatch(setLoading(false));
                    } else {
                        return
                    }
                });

            } else {
                return
            }
        });
    }, [isUpdate]);


    // const loadMoreItems = () => {
    //     const newOffseta = isData.next.split('?')[1];
    //     dispatch(setLoading(true));
    //     microDistrict.list({ urlParams: `?${newOffseta}` }).then((resp) => {
    //         if (resp.success) {
    //             setIsData((prevData) => ({
    //                 ...prevData,
    //                 results: [...prevData.results.microdistricts, ...resp.data.results.microdistricts],
    //                 next: resp.data.next,
    //                 count: resp.data.count,
    //                 previous: resp.data.previous
    //             }));
    //             dispatch(setLoading(false));
    //             if (resp.data.next === null) {
    //                 setIsLoading(false)
    //             }
    //         }
    //     });
    // };

    const filterItems = () => {

        dispatch(setLoading(true));
        const queryParams = fieldToArray(filterRenderer).map(item => `${item.key}=${item.value}`).join('&');
        const urlParams = `?${queryParams}`;

        microDistrict.list({ urlParams }).then(resp => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    microdistricts: resp.data.microdistricts,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));

                setIsFilter(false);
                dispatch(setLoading(false));
            }
        });
    };


    // const shouldShowLoadMoreButton = () => {
    //     return isData?.results?.length >= 0 && isData?.results?.length < isData?.count;
    // };

    const dischardMicrodist = () => {
        setIsLoading(true)
        const dishardFormDataMunic = new FormData()
        isDishardMicrodistMunic.files.forEach((file, index) => {
            dishardFormDataMunic.append(`files${index}`, file);

        });
        const dishardFormDataOne = new FormData()
        isDishardMicrodistOne.files.forEach((file, index) => {
            dishardFormDataOne.append(`files${index}`, file);

        });
        const dishardFormDataTwo = new FormData()
        isDishardMicrodistTwo.files.forEach((file, index) => {
            dishardFormDataTwo.append(`files${index}`, file);

        });

        microDistrict.dischargeMunic(dishardFormDataMunic).then((resp) => {
            dispatch(setLoading(true));
            if (resp.success) {
                microDistrict.dischargeMicrodist(dishardFormDataOne).then((resp) => {
                    if (resp.success) {
                        microDistrict.dischargeMicrodistName(dishardFormDataTwo).then((resp) => {
                            if (resp.success) {
                                setIsUpdate(true)
                                setIsModal(false)
                                setIsLoading(false)
                            }
                        })
                    }
                })

            }
        })
    }

    const clearFilter = () => {
        setIsUpdate(!isUpdate)
        dispatch(cleardataMicrodistrictFilter())
        setIsFilter(false)
    }

    useEffect(() => {
        localStorage.setItem('filterMicro', JSON.stringify(filterRenderer));
    }, [filterRenderer])

    // const shouldShowLoadMoreButton = () => {
    //     return isData?.results?.microdistricts?.length >= 0 && isData?.results?.microdistricts?.length < isData?.count;
    // };





    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <div className='conainerPage'>
                        {
                            // isModal &&
                            // <Modal
                            //     content={
                            //         isLoading?
                            //         <ContentLoader/>
                            //         :
                            //         <div className='containerModal'>
                            //             <h1>Импорт микрорайонов</h1>

                            //             <p>Муниципалитеты</p>
                            //             <FilePicker
                            //                 isUpload={true}
                            //                 type={'shape'}
                            //                 setFiles={(e) => {
                            //                     setIsDishardMicrodistMunic((prevIsDishardLandploat) => ({
                            //                         ...prevIsDishardLandploat,
                            //                         ['files']: e
                            //                     }))
                            //                 }}
                            //             />
                            //             <p>Микрорайоны</p>
                            //             <FilePicker
                            //                 isUpload={true}
                            //                 type={'shape'}
                            //                 setFiles={(e) => {
                            //                     setIsDishardMicrodistOne((prevIsDishardLandploat) => ({
                            //                         ...prevIsDishardLandploat,
                            //                         ['files']: e
                            //                     }))
                            //                 }}
                            //             />
                            //             <p>Название микрорайонов</p>
                            //             <FilePicker
                            //                 isUpload={true}
                            //                 type={'shape'}
                            //                 setFiles={(e) => {
                            //                     setIsDishardMicrodistTwo((prevIsDishardLandploat) => ({
                            //                         ...prevIsDishardLandploat,
                            //                         ['files']: e
                            //                     }))
                            //                 }}
                            //             />
                            //             {
                            //                 isDishardMicrodistMunic?.files &&
                            //                 <>
                            //                 <p>Муниципалитеты</p>
                            //                 <FileAttached
                            //                     filesData={isDishardMicrodistMunic?.files}
                            //                     addFile={false}
                            //                 />
                            //                 </>
                            //             }
                            //             {
                            //                 isDishardMicrodistOne?.files &&
                            //                 <>
                            //                 <p>Микрорайоны</p>
                            //                 <FileAttached
                            //                     filesData={isDishardMicrodistOne?.files}
                            //                     addFile={false}
                            //                 />
                            //                 </>
                            //             }
                            //             {
                            //                 isDishardMicrodistTwo?.files &&
                            //                 <>
                            //                 <p>Название микрорайонов</p>
                            //                 <FileAttached
                            //                     filesData={isDishardMicrodistTwo?.files}
                            //                     addFile={false}
                            //                 />
                            //                 </>
                            //             }


                            //             <div className='modalButtonContainer'>
                            //                 <Buttons text={'Отменить'} className={'greyButton'} onClick={() => { setIsModal(false) }} />
                            //                 <Buttons text={'Создать'} onClick={() => { dischardMicrodist() }} />
                            //             </div>
                            //         </div>
                            //     }
                            //     onClose={() => setIsModal(false)}
                            // />
                        }
                        {
                            isFilter &&
                            <Modal
                                content={
                                    <div className='containerModal'>
                                        <h1>Фильтры</h1>
                                        {
                                            fieldToArray(isFilterOption).map((item) => {
                                                return (
                                                    <FormInput
                                                        subInput={item.value.label}
                                                        value={filterRenderer[item.key]}
                                                        options={item.value.choices}
                                                        onChange={(e) => {
                                                            dispatch(setDataMicrodistrictFilterPressItem([item.key, e]));
                                                        }}
                                                    />
                                                )
                                            })
                                        }

                                        <div className='modalButtonContainer'>
                                            <Buttons text={'Принять'} onClick={() => { filterItems() }} />
                                            <Buttons text={'Сбросить'} className={'greyButton'} onClick={() => { clearFilter() }} />

                                        </div>
                                    </div>
                                }
                                onClose={() => setIsFilter(false)}
                            />
                        }
                        <Header
                            name={'Микрорайоны'}
                            icon={icons.markerPin}
                            buttonContent={
                                <div className='regionContainer'>

                                        <Link to={'/microdistrict-reports'}>
                                            <Buttons
                                                text={'Сводные отчеты'}
                                            />
                                        </Link>
                                    <Buttons
                                        circle={fieldToArray(filterRenderer).length > 0 && fieldToArray(filterRenderer).length}
                                        ico={icons.Filter}
                                        text={'Фильтры'}
                                        className={'filterButton'}
                                        onClick={() => { setIsFilter(true) }}
                                    />
                                    {/* <Buttons
                                text={'Импорт микрорайонов'}
                                onClick={() => { setIsModal(true) }}
                            /> */}
                                    {(isAdmin || isCadastral) &&
                                        <Link to={'/microdistrict'}>
                                            <Buttons
                                                text={'Добавить микрорайон'}
                                            />
                                        </Link>
                                    }
                                </div>
                            }
                            lenght={isData?.count}
                        />

                        {

                            (isData?.microdistricts?.length >= 0) &&
                            <>
                                {
                                    // isLoading?
                                    // <ContentLoader/>
                                    // :
                                    <Tables isData={isData.microdistricts} isList={isList} type={'microdistrict'} isTotals={isData?.totals} loading={loading}
                                    //    onLoad={() => shouldShowLoadMoreButton() && loadMoreItems()} 
                                    />
                                }
                            </>
                        }

                    </div>
            }
        </>

    );
}

export default MicrodistrictTablePage;